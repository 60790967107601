import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';
import { openModal, closeModal } from '@redq/reuse-modal';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import Partner from 'common/src/assets/image/okcharlo-favicon-red.jpg';
import {
  BannerWrapper,
  DiscountWrapper,
  DiscountLabel,
  VideoModalWrapper,
  VideoModal,
  PlayButton,
  // Button,
  ModalInnerWrapper,
} from './banner.style';

import { ic_play_circle_filled } from 'react-icons-kit/md/ic_play_circle_filled';
import { play } from 'react-icons-kit/entypo/play';



const BannerSection = ({
  row,
  contentWrapper,
  discountAmount,
  discountText,
  title,
  description,
  subTitle,
  subTitleSecond,
  imageWrapper,
  buttonWrapper,
  buttonWrapperConfirm,
  button,
  fillButton,
  buttonNope,
  buttonBlue,
  buttonYes,
  imageArea
}) => {

  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <Box {...imageArea} className="imageArea">
              <Image src={Partner} alt="Domain Image" />
            </Box>
            <Heading
              {...title}
              as="h1"
              content={[<span className="highlightText"> All good !</span>]}
            />
            <Box {...buttonWrapper}>
              <Link to="/">
                <Button {...fillButton} title="Take me back" />    
              </Link>
            </Box>            
            <Heading
              {...subTitle}
              content="You should receive your pack in a few minutes."
            />
            <Heading
              {...subTitleSecond}
              content={[
                "It is possible that it falls in your spam folder or in the promotions tab of Gmail. If so, add it to your contacts so you don't miss our next pigeon.",
              ]}
            />
            <Heading
              {...subTitle}
              content={[
                "Want to get in touch?",
                <br />,
                "reach out to us at ",
                <span className="highlightText">barefoot@remotementors.io</span>
              ]}
            />            
          </Box>
            {/* <Heading
              as="h1"
              className="highlightText"
              {...subTitle}
              content={["Interested ?", <br/>, "Then click leave us your info."]}
            />               */}
            <Box {...buttonWrapper}>
              <Link to="/">
                <Button {...fillButton} title="Take me back" />    
              </Link>
            </Box>  
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  subTitle: PropTypes.object,
  subTitleSecond: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  buttonWrapperConfirm: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
  buttonYes: PropTypes.object,
  buttonNope: PropTypes.object,
  buttonBlue: PropTypes.object,
  imageArea: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  contentWrapper: {
    width: '100%',
    maxWidth: '1100px',
    mb: ['0', '0', '0', '0', '0'],
    mt: ['0'],
  },
  title: {
    fontSize: ['32px', '36px', '40px', '40px', '74px'],
    color: '#0B0044',
    mb: ['40px', '45px', '45px', '45px', '45px'],
    lineHeight: ['1.2', '1.1', '1.1', '1.1', 1],
  },
  subTitle: {
    fontSize: ['26px', '28px', '32px', '36px', '44px'],
    color: '#0B0044',
    mb: ['40px', '45px', '45px', '45px', '45px'],
    lineHeight: ['1.3', '1.3', '1.2', '1.2', '1.1'],
  },
  subTitleSecond: {
    fontSize: ['26px', '28px', '32px', '36px', '44px'],
    color: '#7a82ae',
    mb: ['0', '0', '0', '0', '45px'],
    lineHeight: ['1.3', '1.3', '1.2', '1.2', '1.1'],
  },
  description: {
    fontSize: ['18px', '18px', '20px', '22px', '24px'],
    fontWeight: '400',
    color: '#6b6d96',
    lineHeight: '1.35',
    mb: '0',
    mt: ['10px', '15px', '15px', '15px', '15px'],
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: '#fff',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '400',
    color: '#fff',
    mb: 0,
    as: 'span',
  },
  fillButton: {
    type: 'button',
    fontSize: ['16px', '18px', '24px'],
    borderRadius: '3px',
    border: 'none',
    p: ['0px 18px', '16px 28px'],
    colors: 'primaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px', 'auto'],
    minHeight: 'auto',
    textTransform: 'none'
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'start',
    mt: ['35px', '60px', '100px'],
    mb: ['35px', '60px', '100px'],
  },
  buttonWrapperConfirm: {
    flexBox: true,
    width: '100%',
    justifyContent: 'start',
    mt: ['35px', '60px', '100px'],
    mb: ['35px', '60px', '100px'],
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'start',
    mt: ['35px', '60px', '100px'],
    mb: ['35px', '60px', '100px'],
  },
  buttonNope: {
    type: 'button',
    fontSize: ['16px', '18px', '24px'],
    borderRadius: '3px',
    border: 'none',
    p: ['0px 15px', '16px 24px'],
    color: 'textColor',
    colors: 'transparentBg',
    border: 'none',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px', 'auto'],
    minHeight: 'auto',
    textTransform: 'none',
    marginRight: '20px'
  },
  buttonYes: {
    type: 'button',
    fontSize: ['16px', '18px', '24px'],
    borderRadius: '3px',
    border: 'none',
    p: ['0px 15px', '16px 28px'],
    colors: 'secondaryWithBg',
    border: 'none',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px', 'auto'],
    minHeight: 'auto',
    textTransform: 'none',
    marginRight: '20px'
  },
  buttonBlue: {
    type: 'button',
    fontSize: ['16px', '18px', '24px'],
    borderRadius: '3px',
    border: 'none',
    p: ['0px 15px', '16px 28px'],
    colors: 'primaryWithBg',
    border: 'none',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px', 'auto'],
    minHeight: 'auto',
    textTransform: 'none',
    marginRight: '20px'
  },
  imageArea: {
    width: ['100%', '100%', '30%', '36%', '40%'],
    maxWidth: '150px',
    mb: ['2rem'],
    mt: ['0', '0', '0', '10px', '10px'],
    p: ['0'],
  }  
};

export default BannerSection;
