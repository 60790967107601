import { ResetCSS } from 'common/src/assets/css/style';
import logoImage from 'common/src/assets/image/logos/SVG/logo-blue.svg';
import Image from 'common/src/components/Image';
import { charityTheme } from 'common/src/theme/charity';
import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import SEO from '../components/seo';
import {
  CharityWrapper,
  ContentWrapper,
  GlobalStyle,
} from '../containers/Charity/charity.style';
import Pack from '../containers/Charity/Pack/merciPack';
// import RiskReversalBlock from '../containers/Charity/RiskReversalBlock';
import { loadStripe } from '@stripe/stripe-js';

// Learning
// To best leverage Stripe’s advanced fraud functionality,
// include this script on every page, not just the checkout page.
// This allows Stripe to detect anomalous behavior that may be indicative
// of fraud as customers browse your website.
// Note: This is why we are adding it to a Layout component.

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

export default () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <SEO
          url="http://remotementors.io"
          app_id="834596404033480"
          title="🟣 Remote Mentors - pricing"
          description="We are on a mission to facilitate Remote Work around the globe by sharing experiences and best practices from remote work veterans and nomad elite."
          // meta={[
          //   {title:"Remote Mentors | Level up your remote work game and work from anywhere"},
          //   { description: "Video Learning for badass remote worker : learn from more than 45 remote work veterans and nomad elite and level up your remote work game" },
          //   {charset: "utf-8"},
          //   {'msapplication-TileColor': "#ffffff"},
          //   {'msapplication-TileImage': "images/icons/ms-icon-144x144.png"},
          //   {'theme-color': "#ffffff"},
          //   {viewport: "width=device-width, initial-scale=1"}
          // ]}
          keywords={[
            'remote work',
            'digital nomads',
            'future of work',
            'wofa',
            'work from anywhere',
            'bali',
            'sundesk',
            'dojo',
            'outpost',
          ]}
        />
        <ResetCSS />
        <GlobalStyle />
        <CharityWrapper>
          <ContentWrapper>
            <Link to="/" className="logo">
              <Image
                className="logoMarkPricing"
                src={logoImage}
                alt="Remote Mentors"
              />
            </Link>
            <Pack />
          </ContentWrapper>
        </CharityWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
